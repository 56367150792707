import React from "react";
//import './../../assets/dist/js/adminlte.js'
//import './../../assets/plugins/jquery-mousewheel/jquery.mousewheel.js'
//import './../../assets/plugins/jquery-validation/jquery.validate.min.js'
//import './../../assets/plugins/jquery-validation/additional-methods.min.js'
export default function Footer() {
    return (
        <>
            <footer className="main-footer">
                <strong>
                    Copyright &copy; <a href="/#">charumindworks.com</a>.
                </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block"></div>
            </footer>
        </>
    );
}
