import React, { Component } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Url from "../../configure.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Addfaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      status: 1,
      loading: false,
    };
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    var firstFormData = new FormData();
    firstFormData.append("title", this.state.title);
    firstFormData.append("description", this.state.description);
    firstFormData.append("status", this.state.status);

    this.setState({
      loading: true,
    });
    axios
      .post(Url.baseUrl + "api/faq-add", firstFormData, {})
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            loading: false,
          });
          window.history.back();
        }
        if (res.data.status === "failed" && res.data.success === undefined) {
          this.setState({
            loading: false,
            errMsgTitle: res.data.errors.title,
            errMsgDes: res.data.errors.description,
          });
        }
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <>
        {this.state.loading ? (
          <div
            className="loader"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate3d(-50%, -50%, 0px)",
            }}
          >
            <div className="imgs">
              <img src="/assets/img/preloader.png" />
              <img src="/assets/img/loader-bg.png" className="circle" />
            </div>
          </div>
        ) : (
          ""
        )}
        <section className="content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h2 className="m-0">Add Faq</h2>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/admin/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add Faq</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <form id="quickForm" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        onChange={this.handleInputChange}
                        value={this.state.title}
                      />
                      <span className="text-danger">
                        {this.state.errMsgTitle}
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Description</label>
                      <textarea
                        name="description"
                        className="form-control"
                        id="description"
                        placeholder="Description"
                        onChange={this.handleInputChange}
                        value={this.state.description}
                      ></textarea>
                      <span className="text-danger">
                        {this.state.errMsgDes}
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        className="form-control"
                        id="status"
                        value={this.state.status}
                        onChange={this.handleInputChange}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="card-footer">
                    <ToastContainer />
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
