import React from "react";

export default function Page404() {
    return (
        <div className="container">
            <div
                className="row"
                style={{ textAlign: "center", marginTop: "10%" }}
            >
                <div className="col-md-12">
                    <div className="error-template">
                        <h1>Oops!</h1>
                        <h2>404 Not Found</h2>
                        <div className="error-details">
                            Sorry, an error has occured, Requested page not
                            found!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
