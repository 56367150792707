import React from 'react'

export default function Page403() {
  return (
    <div>
        <h1>Page403 | Forbidden.</h1>
        <h3>Access Denied.</h3>
    </div>
  )
}
